import {Injectable} from '@angular/core';
import {UntypedFormControl, ValidationErrors} from '@angular/forms';
import {col, row} from '@lib/utils';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {FieldLabel} from '@shared/analysis/field-label';
import {createProductTab} from '@shared/analysis/forms/forms.helpers';
import {interestRates} from '@shared/analysis/interest-rate.definitions';
import {InterestRate} from '@shared/analysis/models/interest-rate';
import {isNilOrEmpty} from '@shared/lib';
import {FormlyTypes} from 'src/shared/ui/formly/formly.enums';
import {
  createFulfillmentField,
  createNamedGroup,
  createTabs,
  getBasicField,
  getSelectField,
} from 'src/shared/ui/formly/formly.utils';
import {assetNames, AssetType, RegularPaymentType} from '../models/asset';
import {getOptionsFromNames, investmentStrategyNames} from '../models/assets.enums';
import {InvestmentStrategy} from '../models/investment-products';
import {getAssetValidators} from '../validations.helpers';
import {AbstractAssetsDefinitions} from './abstract-assets.definitions';
import {AssetDefinition, FinancialProductTab} from './definitions.models';

const getInterestRate = (investmentStrategy: InvestmentStrategy): InterestRate => {
  switch (investmentStrategy) {
    case InvestmentStrategy.Conservative:
      return interestRates.unitTrustStrategyConservative;
    case InvestmentStrategy.Balanced:
      return interestRates.unitTrustStrategyBalanced;
    case InvestmentStrategy.Dynamic:
      return interestRates.unitTrustStrategyDynamic;
    default:
      return null;
  }
};

const getInterestRateLimits = (field: FormlyFieldConfig): InterestRate => {
  return getInterestRate(field.form.get('strategy').value);
};

const rateValidator = (control: UntypedFormControl): ValidationErrors => {
  const rate = getInterestRate(control.parent.get('strategy').value);
  if (isNilOrEmpty(control.value)) return null;
  return rate.min > control.value || rate.max < control.value ? {yearlyRate: true} : null;
};

@Injectable()
export class InvestmentProducts {
  constructor(private abstractAssets: AbstractAssetsDefinitions) {}

  unitTrust(): AssetDefinition {
    const type = AssetType.UnitTrust;
    const commonInvestmentFields = this.abstractAssets.commonInvestment();

    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName', [
          row([
            col(commonInvestmentFields.presentValue),
            col(commonInvestmentFields.presentValueDate),
          ]),
          this.abstractAssets.getRelatedAndStakeholderField(type),
        ]),
        this.abstractAssets.getCommonFinancialProductGroup('payment'),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        createNamedGroup(
          'Strategie',
          row([
            col(
              getSelectField(
                'strategy',
                FieldLabel.STRATEGY,
                getOptionsFromNames(investmentStrategyNames),
                {
                  change: (field: FormlyFieldConfig) =>
                    field.form.get('yearlyRate').updateValueAndValidity(),
                },
              ),
            ),
            col(
              getBasicField(
                FormlyTypes.Percentage,
                'yearlyRate',
                FieldLabel.YEARLY_RATE,
                {precision: 2},
                {
                  'templateOptions.min': (_: any, __: any, field: FormlyFieldConfig) => {
                    const rate = getInterestRateLimits(field);
                    return rate ? rate.min : null;
                  },
                  'templateOptions.max': (_: any, __: any, field: FormlyFieldConfig) => {
                    const rate = getInterestRateLimits(field);
                    return rate ? rate.max : null;
                  },
                },
                null,
                [rateValidator],
              ),
            ),
          ]),
        ),
        createNamedGroup('Detaily smlouvy', row([col(commonInvestmentFields.targetAmount)])),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
        this.abstractAssets.getJustificationGroup(),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs, createFulfillmentField()],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  realEstateFund(): AssetDefinition {
    const type = AssetType.RealEstateFund;
    const commonInvestmentFields = this.abstractAssets.commonInvestment();
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName', [
          row([
            col(commonInvestmentFields.presentValue),
            col(commonInvestmentFields.presentValueDate),
          ]),
          this.abstractAssets.getRelatedAndStakeholderField(type),
        ]),

        this.abstractAssets.getCommonFinancialProductGroup('payment'),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        createNamedGroup('Strategie', commonInvestmentFields.yearlyRate),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
        this.abstractAssets.getJustificationGroup(),
      ),

      createProductTab(
        FinancialProductTab.Extra,
        createNamedGroup('Detaily smlouvy', row([col(commonInvestmentFields.targetAmount)])),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs, createFulfillmentField()],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  commodity(): AssetDefinition {
    const type = AssetType.Commodity;
    const commonInvestmentFields = this.abstractAssets.commonInvestment();
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName', [
          row([
            col(commonInvestmentFields.presentValue),
            col(commonInvestmentFields.presentValueDate),
          ]),
          this.abstractAssets.getRelatedAndStakeholderField(type),
        ]),

        this.abstractAssets.getCommonFinancialProductGroup('payment'),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        createNamedGroup('Strategie', commonInvestmentFields.yearlyRate),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
        this.abstractAssets.getJustificationGroup(),
      ),

      createProductTab(
        FinancialProductTab.Extra,
        createNamedGroup('Detaily smlouvy', row([col(commonInvestmentFields.targetAmount)])),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs, createFulfillmentField()],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  exchangeTradedFunds(): AssetDefinition {
    const type = AssetType.ExchangeTradedFunds;
    const commonInvestmentFields = this.abstractAssets.commonInvestment();
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName', [
          row([
            col(commonInvestmentFields.presentValue),
            col(commonInvestmentFields.presentValueDate),
          ]),
          this.abstractAssets.getRelatedAndStakeholderField(type),
        ]),

        this.abstractAssets.getCommonFinancialProductGroup('payment'),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        createNamedGroup('Strategie', commonInvestmentFields.yearlyRate),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
        this.abstractAssets.getJustificationGroup(),
      ),

      createProductTab(
        FinancialProductTab.Extra,
        createNamedGroup('Detaily smlouvy', row([col(commonInvestmentFields.targetAmount)])),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs, createFulfillmentField()],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  certificates(): AssetDefinition {
    const type = AssetType.Certificates;
    const commonInvestmentFields = this.abstractAssets.commonInvestment();
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName', [
          row([
            col(commonInvestmentFields.presentValue),
            col(commonInvestmentFields.presentValueDate),
          ]),
          this.abstractAssets.getRelatedAndStakeholderField(type),
        ]),

        this.abstractAssets.getCommonFinancialProductGroup('payment'),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        createNamedGroup('Strategie', commonInvestmentFields.yearlyRate),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
        this.abstractAssets.getJustificationGroup(),
      ),

      createProductTab(
        FinancialProductTab.Extra,
        createNamedGroup('Detaily smlouvy', row([col(commonInvestmentFields.targetAmount)])),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs, createFulfillmentField()],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  combinedDeposits(): AssetDefinition {
    const type = AssetType.CombinedDeposits;
    const commonInvestmentFields = this.abstractAssets.commonInvestment();
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName', [
          row([
            col(commonInvestmentFields.presentValue),
            col(commonInvestmentFields.presentValueDate),
          ]),
          this.abstractAssets.getRelatedAndStakeholderField(type),
        ]),

        this.abstractAssets.getCommonFinancialProductGroup('payment'),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        createNamedGroup('Strategie', commonInvestmentFields.yearlyRate),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
        this.abstractAssets.getJustificationGroup(),
      ),

      createProductTab(
        FinancialProductTab.Extra,
        createNamedGroup('Detaily smlouvy', row([col(commonInvestmentFields.targetAmount)])),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs, createFulfillmentField()],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  buildingSavings(): AssetDefinition {
    const type = AssetType.BuildingSavings;
    const commonInvestmentFields = this.abstractAssets.commonInvestment();
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName', [
          row([
            col(commonInvestmentFields.presentValue),
            col(commonInvestmentFields.presentValueDate),
          ]),
          this.abstractAssets.getRelatedAndStakeholderField(type),
        ]),
        this.abstractAssets.getCommonFinancialProductGroup('payment'),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        createNamedGroup('Strategie', this.abstractAssets.yearlyRate(interestRates[type])),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
        this.abstractAssets.getJustificationGroup(),
      ),

      createProductTab(
        FinancialProductTab.Extra,
        createNamedGroup('Detaily smlouvy', row([col(commonInvestmentFields.targetAmount)])),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs, createFulfillmentField()],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  supplementaryPensionSavings(): AssetDefinition {
    const type = AssetType.SupplementaryPensionSavings;
    const commonInvestmentFields = this.abstractAssets.commonInvestment();
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName', [
          row([
            col(commonInvestmentFields.presentValue),
            col(commonInvestmentFields.presentValueDate),
          ]),
          this.abstractAssets.getRelatedAndStakeholderField(type),
        ]),
        this.abstractAssets.getCommonFinancialProductGroup('payment', [
          row([
            col(
              getBasicField(
                FormlyTypes.Currency,
                'employerContribution',
                FieldLabel.EMPLOYER_CONTRIBUTION,
              ),
            ),
            col(
              getBasicField(
                FormlyTypes.Checkbox,
                'conservationPossibility',
                FieldLabel.CONSERVATION_POSSIBILITY,
              ),
            ),
          ]),
        ]),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        createNamedGroup(
          'Strategie',
          row([
            col(
              getSelectField(
                'strategy',
                FieldLabel.STRATEGY,
                getOptionsFromNames(investmentStrategyNames),
                {
                  change: (field: FormlyFieldConfig) =>
                    field.form.get('yearlyRate').updateValueAndValidity(),
                },
              ),
            ),
            col(
              getBasicField(
                FormlyTypes.Percentage,
                'yearlyRate',
                FieldLabel.YEARLY_RATE,
                {precision: 2},
                {
                  'templateOptions.min': (_: any, __: any, field: FormlyFieldConfig) => {
                    const rate = getInterestRateLimits(field);
                    return rate ? rate.min : null;
                  },
                  'templateOptions.max': (_: any, __: any, field: FormlyFieldConfig) => {
                    const rate = getInterestRateLimits(field);
                    return rate ? rate.max : null;
                  },
                },
                null,
                [rateValidator],
              ),
            ),
          ]),
        ),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
        this.abstractAssets.getJustificationGroup(),
      ),

      createProductTab(
        FinancialProductTab.Extra,
        createNamedGroup('Detaily smlouvy', row([col(commonInvestmentFields.targetAmount)])),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs, createFulfillmentField()],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  pensionInsurance(): AssetDefinition {
    const type = AssetType.PensionInsurance;
    const commonInvestmentFields = this.abstractAssets.commonInvestment();
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName', [
          row([
            col(commonInvestmentFields.presentValue),
            col(commonInvestmentFields.presentValueDate),
          ]),
          this.abstractAssets.getRelatedAndStakeholderField(type),
        ]),
        this.abstractAssets.getCommonFinancialProductGroup('payment', [
          getBasicField(
            FormlyTypes.Currency,
            'employerContribution',
            FieldLabel.EMPLOYER_CONTRIBUTION,
          ),
        ]),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        createNamedGroup('Strategie', this.abstractAssets.yearlyRate(interestRates[type])),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
        this.abstractAssets.getJustificationGroup(),
      ),

      createProductTab(
        FinancialProductTab.Extra,
        createNamedGroup('Detaily smlouvy', row([col(commonInvestmentFields.targetAmount)])),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs, createFulfillmentField()],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  savingsAccount(): AssetDefinition {
    const type = AssetType.SavingsAccount;
    const commonInvestmentFields = this.abstractAssets.commonInvestment();
    const commonFinancialProducts = this.abstractAssets.getCommonFinancialProductFields({
      value: {label: FieldLabel.REGULAR_DEPOSIT},
    });
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName', [
          row([
            col(commonInvestmentFields.presentValue),
            col(commonInvestmentFields.presentValueDate),
          ]),
          this.abstractAssets.getRelatedAndStakeholderField(type),
        ]),

        createNamedGroup(
          'Vklad',
          row([col(commonFinancialProducts.value), col(commonFinancialProducts.frequency)]),
        ),

        this.abstractAssets.getCommonFinancialProductGroup('period'),
        createNamedGroup('Strategie', this.abstractAssets.yearlyRate(interestRates[type])),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
        this.abstractAssets.getJustificationGroup(),
      ),

      createProductTab(
        FinancialProductTab.Extra,
        createNamedGroup('Detaily smlouvy', row([col(commonInvestmentFields.targetAmount)])),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs, createFulfillmentField()],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  termDeposit(): AssetDefinition {
    const type = AssetType.TermDeposit;
    const commonInvestmentFields = this.abstractAssets.commonInvestment();

    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName', [
          row([
            col(commonInvestmentFields.presentValue),
            col(commonInvestmentFields.presentValueDate),
          ]),
          this.abstractAssets.getRelatedAndStakeholderField(AssetType.TermDeposit),
        ]),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        createNamedGroup('Strategie', commonInvestmentFields.yearlyRate),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
        this.abstractAssets.getJustificationGroup(),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    const hiddenFields: FormlyFieldConfig[] = [
      {key: 'value', defaultValue: 0},
      {key: 'frequency', defaultValue: RegularPaymentType.Month},
    ];

    return {
      name: assetNames[type],
      type,
      fields: [tabs, createFulfillmentField(), ...hiddenFields],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  bonds(): AssetDefinition {
    const type = AssetType.Bonds;
    const commonInvestmentFields = this.abstractAssets.commonInvestment();

    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName', [
          row([
            col(commonInvestmentFields.presentValue),
            col(commonInvestmentFields.presentValueDate),
          ]),
          this.abstractAssets.getRelatedAndStakeholderField(type),
        ]),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        createNamedGroup('Strategie', commonInvestmentFields.yearlyRate),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
        this.abstractAssets.getJustificationGroup(),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    const hiddenFields: FormlyFieldConfig[] = [
      {key: 'value', defaultValue: 0},
      {key: 'frequency', defaultValue: RegularPaymentType.Month},
    ];

    return {
      name: assetNames[type],
      type,
      fields: [tabs, createFulfillmentField(), ...hiddenFields],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  qualifiedInvestorFunds(): AssetDefinition {
    const type = AssetType.QualifiedInvestorFunds;
    const commonInvestmentFields = this.abstractAssets.commonInvestment();

    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName', [
          row([
            col(commonInvestmentFields.presentValue),
            col(commonInvestmentFields.presentValueDate),
          ]),
          this.abstractAssets.getRelatedAndStakeholderField(type),
        ]),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        createNamedGroup('Strategie', commonInvestmentFields.yearlyRate),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
        this.abstractAssets.getJustificationGroup(),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    const hiddenFields: FormlyFieldConfig[] = [
      {key: 'value', defaultValue: 0},
      {key: 'frequency', defaultValue: RegularPaymentType.Month},
    ];

    return {
      name: assetNames[type],
      type,
      fields: [tabs, createFulfillmentField(), ...hiddenFields],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  otherFinancialProperty(): AssetDefinition {
    const commonFinancialPropertyAsset = this.abstractAssets.commonFinancialPropertyAsset();

    return {
      name: assetNames[AssetType.OtherFinancialProperty],
      type: AssetType.OtherFinancialProperty,
      fields: [
        commonFinancialPropertyAsset.assetUuid,
        row([col(commonFinancialPropertyAsset.value), col(commonFinancialPropertyAsset.start)]),
        commonFinancialPropertyAsset.institutionName,
        commonFinancialPropertyAsset.note,
      ],
      validators: getAssetValidators(AssetType.OtherFinancialProperty),
      model: {},
    };
  }

  getAllForFinances() {
    return [
      this.buildingSavings(),
      this.supplementaryPensionSavings(),
      this.pensionInsurance(),
      this.savingsAccount(),
      this.unitTrust(),
      this.realEstateFund(),
      this.commodity(),
      this.exchangeTradedFunds(),
      this.certificates(),
      this.combinedDeposits(),
    ];
  }

  getAllForProperties() {
    return [
      ...this.getAllForFinances(),
      this.termDeposit(),
      this.bonds(),
      this.qualifiedInvestorFunds(),
      this.otherFinancialProperty(),
    ];
  }
}
