import {createSelector} from '@ngrx/store';
import {assetNames, AssetType} from '@shared/analysis/models/asset';
import {
  VehicleInsuranceAsset,
  VehicleSupplementaryInsurance,
} from '@shared/analysis/models/insurance-products';
import {CommonPropertyAsset, PropertyAsset} from '@shared/analysis/models/properties';
import {
  getMainMovableLabel,
  MainMovableRisks,
  MovableRisks,
  PropertyRisks,
} from '@shared/analysis/models/risks';
import {ObjectiveType} from '@shared/analysis/objectives.helpers';
import {
  selectCurrentAssets,
  selectMovableProperties,
  selectObjectiveAdvisorProposedAssets,
  selectObjectiveClientProposedAssets,
} from '@shared/analysis/store';
import {
  getPropertyInputRisks,
  getPropertyTable,
  getSkipSum,
  introTexts,
  mergePropertyRisks,
  safeNumber,
} from 'src/app/modules/financial-plan/objectives/objectives.helpers';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {
  selectVehicleObjectiveAsset,
  selectVehicleRequirementsAssets,
  setFulfillmentAndRating,
} from 'src/app/modules/financial-plan/store/objectives-common.selectors';
import {
  AssetRisk,
  VehicleObjective,
  InputRisk,
  InsuranceRisk,
  Table,
} from 'src/app/modules/financial-plan/store/objectives.models';

export const selectVehicleObjectives = (opts?: {filterSelectedAssets: boolean}) =>
  createSelector(
    selectCurrentAssets,
    selectObjectiveAdvisorProposedAssets,
    selectObjectiveClientProposedAssets,
    selectVehicleObjectiveAsset,
    selectVehicleRequirementsAssets,
    selectMovableProperties,
    (
      currentAssets,
      advisorProposedAssets,
      clientProposedAssets,
      objectiveAsset,
      requirementsAssets,
      propertyAssets,
    ): VehicleObjective[] => {
      if (!objectiveAsset) return [];

      if (opts?.filterSelectedAssets) {
        propertyAssets = propertyAssets.filter(a =>
          objectiveAsset.selectedAssets?.includes(a.assetUuid),
        );
      }

      return propertyAssets.map((propertyAsset: PropertyAsset): VehicleObjective => {
        const requirementsAsset = requirementsAssets.find(
          asset => asset.relatedPropertyUuid === propertyAsset.assetUuid,
        );
        const inputRisks = getPropertyInputRisks(requirementsAsset);

        const currentTable = getPropertyTable(
          Situation.Current,
          currentAssets,
          propertyAsset,
          objectiveAsset,
          ObjectiveType.Vehicle,
        );
        const advisorProposedTable = getPropertyTable(
          Situation.AdvisorProposed,
          advisorProposedAssets,
          propertyAsset,
          objectiveAsset,
          ObjectiveType.Vehicle,
        );
        const clientProposedTable = getPropertyTable(
          Situation.ClientProposed,
          clientProposedAssets,
          propertyAsset,
          objectiveAsset,
          ObjectiveType.Vehicle,
        );
        const propertyRisks = getPropertyRisks(
          currentTable,
          advisorProposedTable,
          clientProposedTable,
          inputRisks,
        );

        setFulfillmentAndRating(
          currentTable,
          advisorProposedTable,
          clientProposedTable,
          propertyRisks,
        );

        currentTable.rating = null;
        advisorProposedTable.rating = null;
        clientProposedTable.rating = null;

        if (propertyAsset.type !== AssetType.Vehicle) {
          throw new Error(
            `Expected vehicle asset, got ${propertyAsset.type}, id ${propertyAsset.assetUuid}`,
          );
        }

        let name = (propertyAsset as CommonPropertyAsset).name || assetNames[propertyAsset.type];
        const additionalInfo =
          propertyAsset.registrationPlate || propertyAsset.vehicleIdentificationNumber;
        if (additionalInfo) {
          name += ` (${additionalInfo})`;
        }

        return {
          type: ObjectiveType.Vehicle,
          objectiveAsset,
          objectiveValue: null,
          requirementsAsset,
          propertyAsset,
          introText: introTexts[ObjectiveType.Vehicle],
          name,
          input: inputRisks,
          chart: propertyRisks,
          table: {
            current: currentTable,
            advisorProposed: advisorProposedTable,
            clientProposed: clientProposedTable,
          },
        };
      });
    },
  );

function getPropertyRisks(
  currentTable: Table,
  advisorProposedTable: Table,
  clientProposedTable: Table,
  inputRisks: InputRisk[],
): InsuranceRisk[] {
  inputRisks = adjustMovableInputRisks(inputRisks);

  const currentPropertyRisks = mapAssetsToPropertyRisks(
    currentTable,
    inputRisks,
    Situation.Current,
  );
  const advisorProposedPropertyRisks = mapAssetsToPropertyRisks(
    advisorProposedTable,
    inputRisks,
    Situation.AdvisorProposed,
  );
  const clientProposedPropertyRisks = mapAssetsToPropertyRisks(
    clientProposedTable,
    inputRisks,
    Situation.ClientProposed,
  );
  return mergePropertyRisks(
    currentPropertyRisks,
    advisorProposedPropertyRisks,
    clientProposedPropertyRisks,
    inputRisks,
  );
}

function adjustMovableInputRisks(inputRisks: InputRisk[]): InputRisk[] {
  let adjustedRisks = [...inputRisks];
  adjustedRisks = replaceRisk(adjustedRisks, MovableRisks.CompulsoryInsurance, [
    MainMovableRisks.CompulsoryInsuranceHealth,
    MainMovableRisks.CompulsoryInsuranceProperty,
  ]);
  adjustedRisks = replaceRisk(adjustedRisks, MovableRisks.AccidentInsurance, [
    MainMovableRisks.AccidentInsurance,
  ]);
  return adjustedRisks;
}

function replaceRisk(
  risks: InputRisk[],
  searchKey: PropertyRisks,
  replaceKeys: PropertyRisks[],
): InputRisk[] {
  const index = risks.findIndex(risk => risk.key === searchKey);
  if (index === -1) return risks;
  const replacedRisks: InputRisk[] = replaceKeys.map(key => ({
    key,
    label: getMainMovableLabel(key) ?? key,
  }));
  return [...risks.slice(0, index), ...replacedRisks, ...risks.slice(index + 1)];
}

function mapAssetsToPropertyRisks(
  table: Table,
  inputRisks: InputRisk[],
  situation: Situation,
): AssetRisk[] {
  return inputRisks
    .map((risk: InputRisk) => ({
      key: risk.key,
      value: sumPropertyRisks(risk.key as PropertyRisks, table, situation),
    }))
    .filter((risk: AssetRisk) => !isNaN(risk.value));
}

function sumPropertyRisks(risk: PropertyRisks, table: Table, situation: Situation): number {
  return table.rows.reduce((sum, row) => {
    if (getSkipSum(row.asset, situation)) return sum;
    const asset = row.asset as VehicleInsuranceAsset;
    return isMainMovableRisk(risk)
      ? sum +
          safeNumber(() => asset[risk as MainMovableRisks] * compulsoryInsuranceMultiplier(risk))
      : sum +
          safeNumber(
            () => asset.vehicleSupplementaryInsurance[risk as keyof VehicleSupplementaryInsurance],
          );
  }, 0);
}

function isMainMovableRisk(risk: PropertyRisks): boolean {
  return Object.values(MainMovableRisks).includes(risk as MainMovableRisks);
}

function compulsoryInsuranceMultiplier(risk: PropertyRisks): number {
  return risk === MainMovableRisks.CompulsoryInsuranceHealth ||
    risk === MainMovableRisks.CompulsoryInsuranceProperty
    ? 1_000_000
    : 1;
}
