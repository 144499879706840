import {CommonExpensesAsset} from '@shared/analysis/models/common-expenses';
import {FinancialProductsAsset} from '@shared/analysis/models/financial-products';
import {IncomeAttributesAsset} from '@shared/analysis/models/income-attributes';
import {MainIncomesAsset} from '@shared/analysis/models/main-incomes';
import {ObjectivesAsset, RequirementsAsset} from '@shared/analysis/models/objectives';
import {OtherAsset} from '@shared/analysis/models/other-assets';
import {OtherIncomesAsset} from '@shared/analysis/models/other-incomes';
import {PropertyAsset} from '@shared/analysis/models/properties';
import {SandboxesAsset} from '@shared/analysis/models/sandbox';
import {VirtualAsset} from '@shared/analysis/models/virtual-assets';

export enum AssetType {
  // Sandbox Assets - to be (re)moved
  SandboxCurrentBalance = 'sandboxCurrentBalance',
  SandboxIncome = 'sandboxIncome',
  SandboxExpenses = 'sandboxExpenses',
  SandboxUnitTrust = 'sandboxUnitTrust',
  SandboxMortgage = 'sandboxMortgage',
  SandboxConsumerLoan = 'sandboxConsumerLoan',
  SandboxHousePurchase = 'sandboxHousePurchase',
  SandboxCarPurchase = 'sandboxCarPurchase',
  SandboxLossOfWork = 'sandboxLossOfWork',
  SandboxInheritance = 'sandboxInheritance',

  // Objectives
  /** Vlastní bydlení */
  OwnHousing = 'ownHousing',
  /** Budoucnost dětí */
  ChildrenFuture = 'childrenFuture',
  /** Odchod do penze */
  Retirement = 'retirement',
  /** Finanční nezávislost */
  FinancialIndependence = 'financialIndependence',
  /** Krátkodobá rezerva */
  ShortTermReserve = 'shortTermReserve',
  /** Investice */
  LongTermReserve = 'longTermReserve',
  /** Splacení špatných dluhů */
  BadDebtsRepayment = 'badDebtsRepayment',
  /** Zajištění rodiny */
  FamilyProvision = 'familyProvision',
  /** Zajištění majetku */
  PropertyProvision = 'propertyProvision',
  PropertyRequirements = 'propertyRequirements',
  /** Pojištění vozidel */
  VehicleProvision = 'vehicleProvision',
  VehicleRequirements = 'vehicleRequirements',
  // /** Zajištění */
  // Ensurement = 'ensurement',
  /** Narození dítěte */
  ChildBirth = 'childBirth',
  /** Nové bydlení */
  NewHousing = 'newHousing',
  /** Rekonstrukce */
  Reconstruction = 'reconstruction',
  /** Vybavení */
  Furnishings = 'furnishings',
  /** Vozidlo */
  Car = 'car',
  /** Dovolená */
  Vacation = 'vacation',
  /** Jiný cíl */
  OtherObjective = 'otherObjective',

  // Common Expenses
  CommonExpensesSum = 'expensesSum',
  /** Jídlo */
  Food = 'food',
  /** Bydlení */
  HousingExpenses = 'housingExpenses',
  /** Doprava */
  Transport = 'transport',
  /** Drogerie a léky*/
  Drugstore = 'drugstore',
  /** Oblečení a obuv */
  Clothes = 'clothes',
  /** Internet, televize a telefon */
  Communication = 'communication',
  /** Volný čas, hobby */
  Hobbies = 'hobbies',
  /** Děti */
  ChildrenExpenses = 'childrenExpenses',
  /** Dovolená */
  Vacations = 'vacations',
  /** Jiné */
  OtherCommonExpenses = 'otherCommonExpenses',

  // Main Incomes
  /** Zaměstnání */
  Employment = 'employment',
  /** OSVČ */
  Contractor = 'contractor',
  /** Pojištění OSVČ */
  ContractorInsurance = 'contractorInsurance',
  /** Výhled finanční situace */
  FinancialOutlook = 'financialOutlook',
  /** Daňové zvýhodnění na děti */
  ChildrenTaxAdvantage = 'childrenTaxAdvantage',

  // Other Incomes
  /** Mateřská */
  Maternity = 'maternity',
  /** Rodičovská */
  Parental = 'parental',
  /** Dávky v nezaměstnanosti */
  UnemploymentBenefits = 'unemploymentBenefits',
  /** Starobní/invalidní důchod */
  PensionIncome = 'pensionIncome',
  /** Výsluhy/renta */
  OtherPensionIncome = 'otherPensionIncome',
  /** Příjmy z pronájmu */
  RentalIncome = 'rentalIncome',
  /** Pravidelné dividendy */
  Dividends = 'dividendsIncome',
  /** Ostatní pasivní příjmy */
  OtherPassiveIncome = 'otherPassiveIncome',

  // Financial Products - Insurance
  InsuranceProductsSum = 'insuranceProductsSum',
  /** Investiční životní pojištění */
  InvestmentLifeInsurance = 'investmentLifeInsurance',
  /** Rizikové životní pojištění */
  RiskLifeInsurance = 'riskLifeInsurance',
  /** Kapitálové životní pojištění */
  CapitalLifeInsurance = 'capitalLifeInsurance',
  /** Pojištění majetku */
  PropertyInsurance = 'propertyInsurance',
  /** Pojištění odpovědnosti za škodu způsobenou zaměstnavateli */
  LiabilityInsurance = 'liabilityInsurance',
  /** Pojištění vozidel */
  VehicleInsurance = 'vehicleInsurance',
  /** Cestovní pojištění */
  TravelInsurance = 'travelInsurance',
  /** Pojištění podnikatelů */
  BusinessInsurance = 'businessInsurance',

  // Financial Products - Investment
  InvestmentProductsSum = 'investmentProductsSum',
  /** Podílové fondy */
  UnitTrust = 'unitTrust',
  /** Nemovitostní fondy */
  RealEstateFund = 'realEstateFund',
  /** Komodity */
  Commodity = 'commodity',
  /** ETF */
  ExchangeTradedFunds = 'exchangeTradedFunds',
  /** Certifikáty */
  Certificates = 'certificates',
  /** Kombinovaný vklad */
  CombinedDeposits = 'combinedDeposits',
  /** Stavební spoření */
  BuildingSavings = 'buildingSavings',
  /** Doplňkové penzijní spoření */
  SupplementaryPensionSavings = 'supplementaryPensionSavings',
  /** Penzijní připojištění */
  PensionInsurance = 'pensionInsurance',
  /** Spořící účet */
  SavingsAccount = 'savingsAccount',

  // Financial Products - Credit
  CreditProductsSum = 'creditProductsSum',
  /** Hypotéka */
  Mortgage = 'mortgage',
  /** Úvěr ze stavebního spoření */
  BuildingSavingsLoan = 'buildingSavingsLoan',
  /** Leasing */
  Leasing = 'leasing',
  /** Kreditní karta */
  CreditCard = 'creditCard',
  /** Povolený debet / kontokorent */
  Overdraft = 'overdraft',
  /** Spotřebitelský úvěr */
  ConsumerLoan = 'consumerLoan',
  /** Jiné úvěry na FO – IČO */
  OtherIndividualLoan = 'otherIndividualLoan',

  // Immovable Properties
  ImmovablePropertiesSum = 'immovablePropertiesSum',
  /** Byt */
  Flat = 'flat',
  /** Dům */
  House = 'house',
  /** Domácnost */
  Household = 'household',
  /** Rekreační objekt */
  HolidayProperty = 'holidayProperty',
  /** Bytový dům */
  ApartmentBuilding = 'apartmentBuilding',
  /** Garáž */
  Garage = 'garage',
  /** Pozemek */
  Land = 'land',
  /** Ostatní nemovitý majetek */
  OtherImmovableProperty = 'otherImmovableProperty',

  // Movable Properties
  MovablePropertiesSum = 'movablePropertiesSum',
  /** Vozidlo */
  Vehicle = 'vehicle',
  /** Cennosti */
  Valuables = 'valuables',
  /** Věci zvláštní hodnoty (obrazy, meče, …) */
  SpecialValuables = 'specialValuables',

  // Financial Properties
  FinancialPropertiesSum = 'financialPropertiesSum',
  /** Běžný účet / hotovost */
  CurrentAccount = 'currentAccount',
  /** Použití prostředků z běžného účtu / hotovosti */
  CurrentAccountUsage = 'currentAccountUsage',
  /** Termínovaný vklad */
  TermDeposit = 'termDeposit',
  /** Dluhopisy */
  Bonds = 'bonds',
  /** Fondy kvalifikovaných investorů */
  QualifiedInvestorFunds = 'qualifiedInvestorFunds',
  /** Další finanční majetek */
  OtherFinancialProperty = 'otherFinancialProperty',

  // Properties Sums
  /** Spoření a investice */
  InvestmentPropertiesSum = 'investmentPropertiesSum',
  /** Úvěry */
  CreditPropertiesSum = 'creditPropertiesSum',
  /** Pojistné produkty */
  InsurancePropertiesSum = 'insurancePropertiesSum',

  // Virtual Assets – there are not stored to BE
  VirtualMonthlyBalance = 'virtualMonthlyBalance',
}

export const assetNames: Record<AssetType, string> = {
  [AssetType.SandboxCurrentBalance]: 'Současný zůstatek',
  [AssetType.SandboxIncome]: 'Příjem',
  [AssetType.SandboxExpenses]: 'Výdaje',
  [AssetType.SandboxUnitTrust]: 'Podílový fond',
  [AssetType.SandboxMortgage]: 'Hypotéka',
  [AssetType.SandboxConsumerLoan]: 'Spotřebitelský úvěr',
  [AssetType.SandboxHousePurchase]: 'Koupě domu',
  [AssetType.SandboxCarPurchase]: 'Koupě auta',
  [AssetType.SandboxLossOfWork]: 'Ztráta zaměstnání',
  [AssetType.SandboxInheritance]: 'Dědictví',
  [AssetType.OwnHousing]: 'Vlastní bydlení',
  [AssetType.ChildrenFuture]: 'Budoucnost dětí',
  [AssetType.Retirement]: 'Zajištění na penzi',
  [AssetType.FinancialIndependence]: 'Finanční nezávislost',
  [AssetType.ShortTermReserve]: 'Krátkodobá rezerva',
  [AssetType.LongTermReserve]: 'Investice',
  [AssetType.BadDebtsRepayment]: 'Zbavení se špatných dluhů',
  [AssetType.FamilyProvision]: 'Zajištění rodiny',
  [AssetType.PropertyProvision]: 'Zajištění majetku',
  [AssetType.PropertyRequirements]: 'Zajištění majetku - požadavky',
  [AssetType.VehicleProvision]: 'Pojištění vozidel',
  [AssetType.VehicleRequirements]: 'Pojištění vozidel - požadavky',
  [AssetType.ChildBirth]: 'Narození dítěte',
  [AssetType.NewHousing]: 'Nové bydlení',
  [AssetType.Reconstruction]: 'Rekonstrukce',
  [AssetType.Furnishings]: 'Vybavení',
  [AssetType.Car]: 'Vozidlo',
  [AssetType.Vacation]: 'Dovolená',
  [AssetType.OtherObjective]: 'Jiný cíl',
  [AssetType.CommonExpensesSum]: 'Běžné měsíční výdaje',
  [AssetType.Food]: 'Jídlo',
  [AssetType.HousingExpenses]: 'Bydlení',
  [AssetType.Transport]: 'Doprava',
  [AssetType.Drugstore]: 'Drogerie a léky',
  [AssetType.Clothes]: 'Oblečení a obuv',
  [AssetType.Communication]: 'Internet, televize a telefon',
  [AssetType.Hobbies]: 'Volný čas, hobby',
  [AssetType.Vacations]: 'Dovolená',
  [AssetType.ChildrenExpenses]: 'Děti',
  [AssetType.OtherCommonExpenses]: 'Jiné',
  [AssetType.Employment]: 'Zaměstnání',
  [AssetType.Contractor]: 'OSVČ',
  [AssetType.ContractorInsurance]: 'Pojištění OSVČ',
  [AssetType.FinancialOutlook]: 'Výhled finanční situace',
  [AssetType.ChildrenTaxAdvantage]: 'Daňové zvýhodnění na děti',
  [AssetType.Maternity]: 'Mateřská',
  [AssetType.Parental]: 'Rodičovská',
  [AssetType.UnemploymentBenefits]: 'Dávky v nezaměstnanosti',
  [AssetType.PensionIncome]: 'Starobní/invalidní důchod',
  [AssetType.OtherPensionIncome]: 'Výsluhy/renta',
  [AssetType.RentalIncome]: 'Příjmy z pronájmu',
  [AssetType.Dividends]: 'Pravidelné dividendy',
  [AssetType.OtherPassiveIncome]: 'Ostatní pasivní příjmy',
  [AssetType.InsuranceProductsSum]: 'Měsíční výdaje na pojistné produkty',
  [AssetType.InvestmentLifeInsurance]: 'Investiční životní pojištění',
  [AssetType.RiskLifeInsurance]: 'Rizikové životní pojištění',
  [AssetType.CapitalLifeInsurance]: 'Kapitálové životní pojištění',
  [AssetType.PropertyInsurance]: 'Pojištění majetku',
  [AssetType.LiabilityInsurance]: 'Pojištění odpovědnosti za škodu způsobenou zaměstnavateli',
  [AssetType.VehicleInsurance]: 'Pojištění vozidel',
  [AssetType.TravelInsurance]: 'Cestovní pojištění',
  [AssetType.BusinessInsurance]: 'Pojištění podnikatelů',
  [AssetType.InvestmentProductsSum]: 'Měsíční výdaje na spořící a investiční produkty',
  [AssetType.UnitTrust]: 'Podílové fondy',
  [AssetType.RealEstateFund]: 'Nemovitostní fondy',
  [AssetType.Commodity]: 'Komodity',
  [AssetType.ExchangeTradedFunds]: 'ETF',
  [AssetType.Certificates]: 'Certifikáty',
  [AssetType.CombinedDeposits]: 'Kombinovaný vklad',
  [AssetType.BuildingSavings]: 'Stavební spoření',
  [AssetType.SupplementaryPensionSavings]: 'Doplňkové penzijní spoření',
  [AssetType.PensionInsurance]: 'Penzijní připojištění',
  [AssetType.SavingsAccount]: 'Spořící účet',
  [AssetType.CreditProductsSum]: 'Měsíční výdaje na úvěrové produkty',
  [AssetType.Mortgage]: 'Hypotéka',
  [AssetType.BuildingSavingsLoan]: 'Úvěr ze stavebního spoření',
  [AssetType.Leasing]: 'Leasing',
  [AssetType.CreditCard]: 'Kreditní karta',
  [AssetType.Overdraft]: 'Povolený debet / kontokorent',
  [AssetType.ConsumerLoan]: 'Spotřebitelský úvěr',
  [AssetType.OtherIndividualLoan]: 'Jiné úvěry na FO – IČO',
  [AssetType.ImmovablePropertiesSum]: 'Nemovitý majetek',
  [AssetType.Flat]: 'Byt',
  [AssetType.House]: 'Dům',
  [AssetType.Household]: 'Domácnost',
  [AssetType.HolidayProperty]: 'Rekreační objekt',
  [AssetType.ApartmentBuilding]: 'Bytový dům',
  [AssetType.Garage]: 'Garáž',
  [AssetType.Land]: 'Pozemek',
  [AssetType.OtherImmovableProperty]: 'Nemovitý majetek',
  [AssetType.MovablePropertiesSum]: 'Movitý majetek',
  [AssetType.Vehicle]: 'Vozidlo',
  [AssetType.Valuables]: 'Cennosti',
  [AssetType.SpecialValuables]: 'Věci zvláštní hodnoty (obrazy, meče, …)',
  [AssetType.FinancialPropertiesSum]: 'Běžný účet a hotovost',
  [AssetType.CurrentAccount]: 'Běžný účet / hotovost',
  [AssetType.CurrentAccountUsage]: 'Použití prostředků z běžného účtu / hotovosti',
  [AssetType.TermDeposit]: 'Termínovaný vklad',
  [AssetType.Bonds]: 'Dluhopisy',
  [AssetType.QualifiedInvestorFunds]: 'Fondy kvalifikovaných investorů',
  [AssetType.OtherFinancialProperty]: 'Další finanční majetek',
  [AssetType.InvestmentPropertiesSum]: 'Spoření a investice',
  [AssetType.CreditPropertiesSum]: 'Úvěry',
  [AssetType.InsurancePropertiesSum]: 'Pojistné produkty',
  [AssetType.VirtualMonthlyBalance]: 'Měsíční zůstatek',
};

export const commonExpenseNoContractorAssets: AssetType[] = [
  AssetType.Food,
  AssetType.HousingExpenses,
  AssetType.Transport,
  AssetType.Drugstore,
  AssetType.Clothes,
  AssetType.Communication,
  AssetType.Hobbies,
  AssetType.Vacations,
  AssetType.ChildrenExpenses,
  AssetType.OtherCommonExpenses,
];

export const commonExpenseAssets: AssetType[] = [
  ...commonExpenseNoContractorAssets,
  AssetType.Contractor,
];

export const mainIncomesAssets: AssetType[] = [AssetType.Employment, AssetType.Contractor];

export const otherIncomesAssets: AssetType[] = [
  AssetType.Maternity,
  AssetType.Parental,
  AssetType.UnemploymentBenefits,
  AssetType.PensionIncome,
  AssetType.OtherPensionIncome,
  AssetType.RentalIncome,
  AssetType.Dividends,
  AssetType.OtherPassiveIncome,
];

export const incomeAssets: AssetType[] = [...mainIncomesAssets, ...otherIncomesAssets];

export const objectivesAssets: AssetType[] = [
  AssetType.OwnHousing,
  AssetType.ChildrenFuture,
  AssetType.Retirement,
  AssetType.FinancialIndependence,
  AssetType.ShortTermReserve,
  AssetType.FamilyProvision,
  AssetType.PropertyProvision,
  AssetType.VehicleProvision,
  AssetType.LongTermReserve,
  AssetType.BadDebtsRepayment,
  AssetType.ChildBirth,
  AssetType.NewHousing,
  AssetType.Reconstruction,
  AssetType.Furnishings,
  AssetType.Car,
  AssetType.Vacation,
  AssetType.OtherObjective,
];

export const requirementsAssets: AssetType[] = [
  AssetType.PropertyRequirements,
  AssetType.VehicleRequirements,
];

export const insuranceAssets: AssetType[] = [
  AssetType.InvestmentLifeInsurance,
  AssetType.RiskLifeInsurance,
  AssetType.CapitalLifeInsurance,
  AssetType.PropertyInsurance,
  AssetType.LiabilityInsurance,
  AssetType.VehicleInsurance,
  AssetType.TravelInsurance,
  AssetType.BusinessInsurance,
];

export const basicInvestmentAssets: AssetType[] = [
  AssetType.UnitTrust,
  AssetType.RealEstateFund,
  AssetType.Commodity,
  AssetType.ExchangeTradedFunds,
  AssetType.Certificates,
  AssetType.CombinedDeposits,
  AssetType.BuildingSavings,
  AssetType.SavingsAccount,
  AssetType.TermDeposit,
  AssetType.Bonds,
  AssetType.QualifiedInvestorFunds,
];

export const pensionInvestmentAssets: AssetType[] = [
  AssetType.SupplementaryPensionSavings,
  AssetType.PensionInsurance,
];

export const otherInvestmentAssets: AssetType[] = [
  AssetType.TermDeposit,
  AssetType.Bonds,
  AssetType.QualifiedInvestorFunds,
  AssetType.OtherFinancialProperty,
];

export const allInvestmentAssets: AssetType[] = [
  ...basicInvestmentAssets,
  ...pensionInvestmentAssets,
  ...otherInvestmentAssets,
];

export const creditAssets: AssetType[] = [
  AssetType.Mortgage,
  AssetType.BuildingSavingsLoan,
  AssetType.Leasing,
  AssetType.CreditCard,
  AssetType.Overdraft,
  AssetType.ConsumerLoan,
  AssetType.OtherIndividualLoan,
];

export const financialProductAssets: AssetType[] = [
  ...insuranceAssets,
  ...allInvestmentAssets,
  ...creditAssets,
];

export const immovablePropertyAssets: AssetType[] = [
  AssetType.Flat,
  AssetType.House,
  AssetType.Household,
  AssetType.HolidayProperty,
  AssetType.ApartmentBuilding,
  AssetType.Garage,
  AssetType.Land,
  AssetType.OtherImmovableProperty,
];

export const movablePropertyAssets: AssetType[] = [
  AssetType.Vehicle,
  AssetType.Valuables,
  AssetType.SpecialValuables,
];

export const financialPropertyAssets: AssetType[] = [AssetType.CurrentAccount];

export const otherAssets: AssetType[] = [AssetType.CurrentAccountUsage];

export interface BaseAsset {
  assetUuid: string;
  type: AssetType;
  note?: string;
  proposal?: boolean;
  justification?: string;
  advisorProposalState?: AdvisorProposalState;
  clientProposalState?: ClientProposalState;
  originalAssetUuid?: string;
  excludedFromAnalysis?: boolean;
}

export interface ValueAsset {
  value: number;
}

export interface DateAsset {
  start: string;
  end?: string;
}

export interface DescriptionAsset {
  description: string;
}

export interface NameAsset {
  /** Název */
  name: string;
}

export interface SugarAsset {
  sugarUuid?: string;
}

export function isNameAsset(obj: any): obj is NameAsset {
  return obj.hasOwnProperty('name');
}

export interface FamilyMemberAsset {
  familyMemberUuid: string;
}

export function isFamilyMemberAsset(obj: any): obj is FamilyMemberAsset {
  return obj.hasOwnProperty('familyMemberUuid');
}

export interface RelatedObjectiveAsset {
  /** K jaké prioritě se vztahuje */
  relatedObjectiveUuid: string;
}

export interface RelatedPropertyAsset {
  /** K jakému majetku se vztahuje */
  relatedPropertyUuid: string;
}

export enum RegularPaymentType {
  Month = 'month',
  Quarter = 'quarter',
  HalfYear = 'halfYear',
  Year = 'year',
}

export enum AdvisorProposalState {
  New = 'new',
  Unchanged = 'unchanged',
  Updated = 'updated',
  Terminated = 'terminated',
}

export enum ClientProposalState {
  NewClientApproved = 'newClientApproved',
  NewClientUpdated = 'newClientUpdated',
  NewClientRejected = 'newClientRejected',
  Unchanged = 'unchanged',
  AdvisorUpdated = 'advisorUpdated',
  ClientUpdated = 'clientUpdated',
  AdvisorTerminated = 'advisorTerminated',
  ClientTerminated = 'clientTerminated',
}

export enum ContractState {
  New = 'new',
  Unchanged = 'unchanged',
  Updated = 'updated',
  Terminated = 'terminated',
}

export type Asset =
  | SandboxesAsset
  | ObjectivesAsset
  | RequirementsAsset
  | CommonExpensesAsset
  | MainIncomesAsset
  | OtherIncomesAsset
  | IncomeAttributesAsset
  | FinancialProductsAsset
  | PropertyAsset
  | OtherAsset
  | VirtualAsset;
