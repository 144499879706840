import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NotificationService} from '@lib/services';
import {BackendValidationControlDirective} from '@shared/backend-validation/backend-validation-control.directive';
import {cloneDeep, flatten, isArray, isPlainObject, isString, keys, size} from 'lodash';
import {MonoTypeOperatorFunction, Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

export type BackendError = {assetUuid?: string; path: string; messages: string[]};

/**
 * Handles validation errors from the backend and dispatches them to the store.
 */
@Injectable({
  providedIn: 'root',
})
export class BackendValidationService {
  /**
   * (Optional) UUID of the validated entity. Service will ignore errors for other entities.
   */
  private assetUuid: string;

  private active = false;
  private controls = new Map<string, BackendValidationControlDirective>();

  constructor(private notificationService: NotificationService) {}

  activate(assetUuid: string) {
    this.active = true;
    this.assetUuid = assetUuid;
  }

  deactivate() {
    this.active = false;
  }

  addControl(path: string, control: BackendValidationControlDirective) {
    this.controls.set(path, control);
  }

  removeControl(path: string) {
    this.controls.delete(path);
  }

  isActive() {
    return this.active;
  }

  catchValidations<T>(): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>): Observable<T> => {
      return source.pipe(
        tap(() => {
          this.resetControls();
        }),
        catchError((response: HttpErrorResponse) => {
          if (response.status === 400 && isPlainObject(response.error)) {
            this.processErrors(response);
          }
          throw response;
        }),
      );
    };
  }

  getErrors(response: HttpErrorResponse): BackendError[] {
    let error = cloneDeep(response.error);
    if (!error) {
      return [];
    }

    if (error.validationErrors) {
      error = error.validationErrors;
    }

    let assetUuid: string = null;
    if (error.assetUuid) {
      assetUuid = error.assetUuid;
      delete error.assetUuid;
    }

    return keysDeep(error).map(({key, value}) => ({
      assetUuid,
      path: key,
      messages: value,
    }));
  }

  private resetControls() {
    for (const control of this.controls.values()) {
      control.resetError();
    }
  }

  private processErrors(response: HttpErrorResponse) {
    if (!this.isActive()) {
      // do nothing
      return;
    }

    const errors: BackendError[] = this.getErrors(response);

    let unhandledError: BackendError = null;
    for (const error of errors) {
      const control = this.controls.get(error.path);

      if (error.assetUuid && this.assetUuid && error.assetUuid !== this.assetUuid) {
        // error for different asset than currently in the form
        unhandledError = error;
      } else if (control) {
        control.setError(error);
      } else {
        unhandledError = error;
      }
    }

    if (unhandledError) {
      const details = `${unhandledError.path} - ${unhandledError.messages[0]}`;
      this.notificationService.dispatchWarning(`Chyba při ukládání formuláře (${details})`, 0);
    }
  }
}

export function keysDeep(obj: any): {key: string; value: string[]}[] {
  return flatten(
    keys(obj)
      .filter(key => key !== 'assetUuid')
      .map(key => {
        if (isArray(obj[key]) && size(obj[key]) > 0 && isString(obj[key][0])) {
          return {key, value: obj[key]};
        }
        return keysDeep(obj[key]).map(obj2 => ({
          ...obj2,
          key: `${key}.${obj2.key}`,
        }));
      }),
  );
}
