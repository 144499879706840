import {FamilyMember} from '@generated/defs/FamilyMember';
import {createSelector} from '@ngrx/store';
import {
  AdvisorProposalState,
  Asset,
  assetNames,
  RelatedObjectiveAsset,
} from '@shared/analysis/models/asset';
import {StakeholderAsset} from '@shared/analysis/models/financial-products';
import {InsuredPerson, LifeInsuranceAsset} from '@shared/analysis/models/insurance-products';
import {CommonInvestmentAsset} from '@shared/analysis/models/investment-products';
import {PersonRisk, riskLabels} from '@shared/analysis/models/life-insurance-risks';
import {
  getRisksVM,
  PersonalInsuranceSelectionVM,
  RiskVM,
} from '@shared/analysis/models/life-insurance-risks-vm';
import {FamilyProvisionAsset, ObjectiveAsset} from '@shared/analysis/models/objectives';
import {getObjectiveProductAssets, ObjectiveType} from '@shared/analysis/objectives.helpers';
import {
  selectCurrentAssets,
  selectObjectiveAdvisorProposedAssets,
  selectObjectiveClientProposedAssets,
} from '@shared/analysis/store';
import {difference, flatten, keyBy, mapValues, uniq} from 'lodash';
import {
  getCategory,
  getMonthlyValue,
  getSkipSum,
  introTexts,
} from 'src/app/modules/financial-plan/objectives/objectives.helpers';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {
  selectFamilyObjectiveAsset,
  setFulfillmentAndRating,
  sortRowByName,
  sumRows,
} from 'src/app/modules/financial-plan/store/objectives-common.selectors';
import {
  AssetRisk,
  FamilyObjective,
  InputRisk,
  InsuranceRisk,
  Row,
  Table,
} from 'src/app/modules/financial-plan/store/objectives.models';
import {selectLifeInsurancePersons} from 'src/app/modules/life-insurance/life-insurance.selectors';
import {getFamilyMembers} from 'src/store/selectors/family-member.selectors';

export const selectFamilyObjectives = createSelector(
  selectCurrentAssets,
  selectObjectiveAdvisorProposedAssets,
  selectObjectiveClientProposedAssets,
  selectFamilyObjectiveAsset,
  getFamilyMembers,
  selectLifeInsurancePersons,
  (
    currentAssets,
    advisorProposedAssets,
    clientProposedAssets,
    objectiveAsset,
    familyMembers,
    persons,
  ): FamilyObjective[] => {
    if (!objectiveAsset) return [];

    const selections = getRisksVM(objectiveAsset as FamilyProvisionAsset, persons, currentAssets);

    return familyMembers
      .map((familyMember): FamilyObjective => {
        const currentTable = getFamilyProvisionTable(
          Situation.Current,
          currentAssets,
          familyMember,
          objectiveAsset,
        );
        const advisorProposedTable = getFamilyProvisionTable(
          Situation.AdvisorProposed,
          advisorProposedAssets,
          familyMember,
          objectiveAsset,
        );
        const clientProposedTable = getFamilyProvisionTable(
          Situation.ClientProposed,
          clientProposedAssets,
          familyMember,
          objectiveAsset,
        );

        const selection = selections.find(s => s.person.id === familyMember.sugarUuid);
        const personInsuranceRisks = getPersonInsuranceRisks(selection);

        const inputRisks: InputRisk[] = personInsuranceRisks.map(r => ({
          key: r.key,
          label: r.heading,
        }));

        const additionalRisksFromAdvisor = getAdditionalRisksFromAdvisor(
          personInsuranceRisks,
          advisorProposedTable,
          familyMember,
        );

        const currentRisksNotInInput = getAdditionalRisksNotInInput(
          personInsuranceRisks,
          currentTable,
          familyMember,
        );

        const insuranceRisks: InsuranceRisk[] =
          personInsuranceRisks &&
          getInsuranceRisks(
            currentTable,
            advisorProposedTable,
            clientProposedTable,
            familyMember.sugarUuid,
            personInsuranceRisks,
          );

        setFulfillmentAndRating(
          currentTable,
          advisorProposedTable,
          clientProposedTable,
          insuranceRisks,
        );

        currentTable.rating = null;
        advisorProposedTable.rating = null;
        clientProposedTable.rating = null;

        return {
          type: ObjectiveType.Family,
          objectiveAsset,
          objectiveValue: null,
          familyMember,
          introText: introTexts[ObjectiveType.Family],
          name: familyMember.firstName,
          input: inputRisks ?? [],
          additionalRisksFromAdvisor,
          currentRisksNotInInput,
          chart: insuranceRisks ?? [],
          table: {
            current: currentTable,
            advisorProposed: advisorProposedTable,
            clientProposed: clientProposedTable,
          },
        };
      })
      .filter(objective => {
        const isFamilyHead = objective.familyMember.familyHead;
        const hasInputRisks = objective.input.length > 0;
        const hasContract =
          objective.table.current.rows.length > 0 ||
          objective.table.advisorProposed.rows.length > 0;

        return isFamilyHead || hasInputRisks || hasContract;
      });
  },
);

function getPersonInsuranceRisks(selection: PersonalInsuranceSelectionVM): InsuranceRisk[] {
  if (!selection) {
    return [];
  }

  const risks: RiskVM[] = [...selection.leftProvision.risks, ...selection.rightProvision.risks];
  return risks
    .filter(risk => risk.data.active)
    .map((risk): InsuranceRisk => {
      return {
        key: risk.data.key,
        heading: risk.riskDef.label,
        currentValue: NaN,
        advisorProposedValue: NaN,
        clientProposedValue: NaN,
        computedValue: risk.calculatedValue,
      };
    })
    .filter(Boolean);
}

function getFamilyProvisionTable(
  situation: Situation,
  assets: Asset[],
  familyMember: FamilyMember,
  objectiveAsset: ObjectiveAsset,
): Table {
  const isStakeholder = (asset: Asset, member: FamilyMember) =>
    (asset as StakeholderAsset).stakeholderUuid === member.sugarUuid ||
    (!(asset as StakeholderAsset).stakeholderUuid && member.familyHead);

  const relatedAssets = getObjectiveProductAssets(ObjectiveType.Family);

  const rows = assets
    .filter(
      asset =>
        isStakeholder(asset, familyMember) ||
        hasFilledInsuranceRisks(asset, familyMember.sugarUuid),
    )
    .filter(
      asset => (asset as RelatedObjectiveAsset).relatedObjectiveUuid === objectiveAsset.assetUuid,
    )
    .filter(asset => relatedAssets.includes(asset.type))
    .map((asset): Row => {
      const investmentAsset = asset as CommonInvestmentAsset;
      const stakeholder = isStakeholder(asset, familyMember);
      return {
        assetUuid: investmentAsset.assetUuid,
        advisorProposalState: investmentAsset.advisorProposalState,
        clientProposalState: investmentAsset.clientProposalState,
        asset,
        name: investmentAsset.name ? investmentAsset.name : assetNames[investmentAsset.type],
        category: getCategory(asset),
        justification: asset.justification,
        monthlyValue: getMonthlyValue(asset),
        stakeholder,
        skipSum: getSkipSum(asset, situation) || !stakeholder,
      };
    })
    .sort(sortRowByName);

  return {
    rows,
    sum: sumRows(rows),
    fulfillment: null,
    rating: null,
  };
}

function hasFilledInsuranceRisks(asset: Asset, familyMemberUuid: string): boolean {
  return (asset as LifeInsuranceAsset).insuredPersons?.some(
    insuredPerson =>
      insuredPerson.insuredPersonUuid === familyMemberUuid &&
      (hasFilledPredefinedRisks(insuredPerson) || hasFilledOtherRisks(insuredPerson)),
  );
}

function hasFilledPredefinedRisks(insuredPerson: InsuredPerson): boolean {
  return Object.values(PersonRisk).some(
    riskId => Number(insuredPerson[riskId as keyof InsuredPerson]) > 0,
  );
}

function hasFilledOtherRisks(insuredPerson: InsuredPerson): boolean {
  return insuredPerson.otherRisks.some(otherRisk => otherRisk.value > 0);
}

function getInsuranceRisks(
  currentTable: Table,
  advisorProposedTable: Table,
  clientProposedTable: Table,
  personId: string,
  personInsuranceRisks: InsuranceRisk[],
): InsuranceRisk[] {
  const currentRisks = mapAssetsToRiskValues(
    currentTable,
    personId,
    personInsuranceRisks,
    Situation.Current,
  );
  const advisorProposedRisks = mapAssetsToRiskValues(
    advisorProposedTable,
    personId,
    personInsuranceRisks,
    Situation.AdvisorProposed,
  );
  const clientProposedRisks = mapAssetsToRiskValues(
    clientProposedTable,
    personId,
    personInsuranceRisks,
    Situation.ClientProposed,
  );
  return mergeRisks(currentRisks, advisorProposedRisks, clientProposedRisks, personInsuranceRisks);
}

function mapAssetsToRiskValues(
  table: Table,
  personId: string,
  personInsuranceRisks: InsuranceRisk[],
  situation: Situation,
): AssetRisk[] {
  return personInsuranceRisks.map(inputRisk => ({
    key: inputRisk.key,
    value: sumLifeInsuranceRisks(table, personId, inputRisk.key as PersonRisk, situation),
  }));
}

function sumLifeInsuranceRisks(
  table: Table,
  familyMemberUuid: string,
  key: PersonRisk,
  situation: Situation,
): number {
  return table.rows.reduce((sum, row) => {
    if (getSkipSum(row.asset, situation)) return sum;

    const insuredPerson = (row.asset as LifeInsuranceAsset).insuredPersons?.find(
      person => person.insuredPersonUuid === familyMemberUuid,
    );
    if (!insuredPerson) return sum;

    return sum + (Number(insuredPerson[key]) ?? 0);
  }, 0);
}

function mergeRisks(
  currentRisks: AssetRisk[],
  advisorProposedRisks: AssetRisk[],
  clientProposedRisks: AssetRisk[],
  insuranceRisks: InsuranceRisk[],
): InsuranceRisk[] {
  const currentRisksMap = mapValues(keyBy(currentRisks, 'key'), 'value');
  const advisorProposedRisksMap = mapValues(keyBy(advisorProposedRisks, 'key'), 'value');
  const clientProposedRisksMap = mapValues(keyBy(clientProposedRisks, 'key'), 'value');

  return insuranceRisks.map(insuranceRisk => ({
    ...insuranceRisk,
    currentValue: currentRisksMap[insuranceRisk.key],
    advisorProposedValue: advisorProposedRisksMap[insuranceRisk.key],
    clientProposedValue: clientProposedRisksMap[insuranceRisk.key],
  }));
}

function getAdditionalRisksFromAdvisor(
  personInsuranceRisks: InsuranceRisk[],
  table: Table,
  familyMember: FamilyMember,
) {
  return getAdditionalRisks(
    personInsuranceRisks,
    table.rows.filter(row => row.advisorProposalState !== AdvisorProposalState.Terminated),
    familyMember,
  );
}

function getAdditionalRisksNotInInput(
  personInsuranceRisks: InsuranceRisk[],
  table: Table,
  familyMember: FamilyMember,
) {
  return getAdditionalRisks(personInsuranceRisks, table.rows, familyMember);
}

function getAdditionalRisks(
  personInsuranceRisks: InsuranceRisk[],
  rows: Row[],
  familyMember: FamilyMember,
): InputRisk[] {
  const inputRisks = personInsuranceRisks?.map(risk => risk.key) ?? [];

  const tableRisks = uniq(
    flatten(
      rows
        .map(row => row.asset)
        .map(asset => mapAssetToRisks(asset as LifeInsuranceAsset, familyMember.sugarUuid)),
    ),
  );

  const additionalRisks = difference<string>(tableRisks, inputRisks);
  return additionalRisks.map(risk => ({
    key: risk,
    label: riskLabels[risk],
  }));
}

function mapAssetToRisks(asset: LifeInsuranceAsset, personUuid: string): string[] {
  const insuredPerson = asset.insuredPersons?.find(p => p.insuredPersonUuid === personUuid);
  if (!insuredPerson) return [];
  return Object.values(PersonRisk).filter(riskKey => insuredPerson[riskKey] > 0);
}
