import {Injectable} from '@angular/core';
import {col, row} from '@lib/utils';
import {FormlyTypes} from '@shared/ui/formly/formly.enums';
import {createFulfillmentField, getBasicField} from '@shared/ui/formly/formly.utils';
import {assetNames, AssetType} from '../models/asset';
import {getAssetValidators} from '../validations.helpers';
import {AbstractAssetsDefinitions} from './abstract-assets.definitions';
import {AssetDefinition} from './definitions.models';

@Injectable()
export class OtherAssets {
  constructor(private abstractAssets: AbstractAssetsDefinitions) {}

  currentAccountUsage(): AssetDefinition {
    const type = AssetType.CurrentAccountUsage;
    const baseAssetFields = this.abstractAssets.getBaseAssetFields();

    return {
      name: assetNames[type],
      type,
      fields: [
        baseAssetFields.assetUuid,
        baseAssetFields.proposal,
        baseAssetFields.advisorProposalState,
        baseAssetFields.clientProposalState,
        baseAssetFields.originalAssetUuid,

        getBasicField(FormlyTypes.Text, 'name', 'Název', {autofocus: true}),
        row([
          col(getBasicField(FormlyTypes.Currency, 'value', 'Objem použitých prostředků')),
          col(this.abstractAssets.getRelatedObjectiveField(type)),
        ]),
        baseAssetFields.note,
        createFulfillmentField(),
        this.abstractAssets.getJustificationGroup(),
      ],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  getAll(): AssetDefinition[] {
    return [this.currentAccountUsage()];
  }
}
