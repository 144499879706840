import {Injectable} from '@angular/core';
import {col, column, row} from '@lib/utils';
import {select, Store} from '@ngrx/store';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {BaseAssetType} from '@shared/analysis/asset-types/common/base-asset-type';
import {FinancialProductAssetType} from '@shared/analysis/asset-types/common/financial-product-asset-type';
import {DefaultValueService} from '@shared/analysis/default-value.service';
import {FieldLabel} from '@shared/analysis/field-label';
import {AbstractAssetsDefinitions} from '@shared/analysis/forms/abstract-assets.definitions';
import {AssetDefinition, FinancialProductTab} from '@shared/analysis/forms/definitions.models';
import {createProductTab} from '@shared/analysis/forms/forms.helpers';
import {assetNames, AssetType, RegularPaymentType} from '@shared/analysis/models/asset';
import {
  ParticipationType,
  PropertyInsuranceAsset,
} from '@shared/analysis/models/insurance-products';
import {PropertyRequirementsAsset} from '@shared/analysis/models/objectives';
import {ImmovableRisks} from '@shared/analysis/models/risks';
import {selectAllAssets} from '@shared/analysis/store';
import {getAssetValidators} from '@shared/analysis/validations.helpers';
import {FormlyTypes} from '@shared/ui/formly/formly.enums';
import {createNamedGroup, createTabs, getBasicField} from '@shared/ui/formly/formly.utils';
import {map, take} from 'rxjs/operators';
import {State} from 'src/store';

@Injectable()
export class PropertyInsuranceAssetType extends BaseAssetType {
  constructor(
    private store: Store<State>,
    private defaultValueService: DefaultValueService,
    private abstractAssets: AbstractAssetsDefinitions,
    private financialProductAssetType: FinancialProductAssetType,
  ) {
    super();
  }

  async create(
    opts: Pick<
      PropertyInsuranceAsset,
      | 'relatedObjectiveUuid'
      | 'relatedPropertyUuid'
      | 'stakeholderUuid'
      | 'proposal'
      | 'advisorProposalState'
    >,
  ): Promise<Partial<PropertyInsuranceAsset>> {
    const asset: Partial<PropertyInsuranceAsset> = {
      ...(this.defaultValueService.getInsurance() as Partial<PropertyInsuranceAsset>),
      type: AssetType.PropertyInsurance,
      assetUuid: this.createId(),
      relatedObjectiveUuid: opts.relatedObjectiveUuid,
      relatedPropertyUuid: opts.relatedPropertyUuid,
      stakeholderUuid: opts.stakeholderUuid,
      proposal: opts.proposal,
      advisorProposalState: opts.advisorProposalState,
      participationType: ParticipationType.Min,
      otherInsuranceParticipations: [],
      otherRisks: [],
      frequency: RegularPaymentType.Year,
    };

    // There are three ways how PropertyInsuranceAsset can be created:
    //
    // 1. Proposal is created
    // 2. Existing contract is created
    // 3. Existing contract is synchronized from Core
    //
    // If 1, then prefill the contract limits as specified in the requirements asset
    if (opts.proposal && opts.relatedPropertyUuid) {
      const requirements = await this.store
        .pipe(
          select(selectAllAssets),
          map(
            assets =>
              assets.find(
                a =>
                  a.type === AssetType.PropertyRequirements &&
                  a.relatedPropertyUuid === opts.relatedPropertyUuid,
              ) as PropertyRequirementsAsset,
          ),
          take(1),
        )
        .toPromise();

      for (const risk of requirements.risks) {
        if (risk.active && risk.limit) {
          switch (risk.key) {
            case ImmovableRisks.RequiredInsuredAmount:
            case ImmovableRisks.Household:
            case ImmovableRisks.HoldingInsurance:
            case ImmovableRisks.CivilLiability:
              asset[risk.key] = risk.limit;
              break;
            default:
              console.warn('Cannot prefill limit for risk', risk.key);
          }
        }
      }
    }

    return asset;
  }

  getAssetDefinition(): AssetDefinition {
    const type = AssetType.PropertyInsurance;
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getCommonFinancialProductGroup('proposalFields'),
        this.abstractAssets.commonFinancialProduct.name,
        row([
          col(this.abstractAssets.commonFinancialProduct.contractNumber),
          col(this.abstractAssets.getStakeholderField()),
        ]),
        row([
          col(this.abstractAssets.getRelatedObjectiveField(type)),
          col(this.abstractAssets.getRelatedPropertyField(type)),
        ]),
        this.abstractAssets.getCommonFinancialProductGroup('payment'),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        row([
          col(
            getBasicField(
              FormlyTypes.Currency,
              'requiredInsuredAmount',
              FieldLabel.REQUIRED_INSURED_AMOUNT,
            ),
          ),
          col(getBasicField(FormlyTypes.Currency, 'household', FieldLabel.HOUSEHOLD_VALUE)),
        ]),
        row([
          col(
            getBasicField(FormlyTypes.Currency, 'holdingInsurance', FieldLabel.HOLDING_INSURANCE),
          ),
          col(getBasicField(FormlyTypes.Currency, 'civilLiability', FieldLabel.CIVIL_LIABILITY)),
        ]),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
        this.abstractAssets.getJustificationGroup(),
      ),
      createProductTab(
        FinancialProductTab.Extra,
        createNamedGroup(
          'Detaily smlouvy',
          row([col(this.abstractAssets.otherRisks)]),
          ...this.insuranceParticipationFields(),
          row(
            [
              col({
                key: 'otherInsuranceParticipations',
                type: FormlyTypes.Repeatable,
                fieldArray: {
                  fieldGroup: [
                    row([
                      column(
                        [getBasicField(FormlyTypes.Text, 'label', FieldLabel.PARTICIPATION_NAME)],
                        [6, 6],
                      ),
                    ]),
                    ...this.financialProductAssetType.commonInsuranceParticipationFields(),
                  ],
                },
                templateOptions: {
                  addButtonLabel: 'Přidat spoluúčast',
                  deleteButtonPosition: 'bottom',
                },
              }),
            ],
            'd-block pb-4',
          ),
        ),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  private insuranceParticipationFields(): FormlyFieldConfig[] {
    return [
      row([
        col(getBasicField(FormlyTypes.Text, 'placeOfInsurance', FieldLabel.PLACE_OF_INSURANCE)),
        col(getBasicField(FormlyTypes.Text, 'insuranceType', FieldLabel.INSURANCE_TYPE)),
      ]),
      ...this.financialProductAssetType.commonInsuranceParticipationFields(),
    ];
  }
}
